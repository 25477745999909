@use './mixins' as m;
@use './variables' as v;
@use './functions' as f;

// Columns config
//  =================================
$gridconfig: (
    'mobile-small': ('row-padding': 4px,
        'col-padding': 12px,
    ),
    'mobile-medium': ('row-padding': 4px,
        'col-padding': 12px,
    ),
    'mobile-large': ('row-padding': 4px,
        'col-padding': 12px,
    ),
    'small': ('row-padding': 6px,
        'col-padding': 12px,
    ),
    'medium': ('row-padding': 24px,
        'col-padding': 12px,
    ),
    'large': ('row-padding': 44px,
        'col-padding': 12px,
    ),
);

@function calculate-columns($size) {
    @return calc(#{$size} / 12 * 100%);
}

$columns: (
    1: calculate-columns(1),
    2: calculate-columns(2),
    3: calculate-columns(3),
    4: calculate-columns(4),
    5: calculate-columns(5),
    6: calculate-columns(6),
    7: calculate-columns(7),
    8: calculate-columns(8),
    9: calculate-columns(9),
    10: calculate-columns(10),
    11: calculate-columns(11),
    12: calculate-columns(12),
);

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 f.map-deep-get($gridconfig, 'mobile-small', 'row-padding');
    position: relative;
    width: 100%;
    max-width: v.$layout-max-width;

    @include m.bp(sm) {
        padding: 0 f.map-deep-get($gridconfig, 'small', 'row-padding');
    }

    @include m.bp(md) {
        padding: 0 f.map-deep-get($gridconfig, 'medium', 'row-padding');
    }

    @include m.bp(lg) {
        padding: 0 f.map-deep-get($gridconfig, 'large', 'row-padding');
    }

    .column {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 f.map-deep-get($gridconfig, 'small', 'col-padding');
        position: relative;

        @include m.bp(md) {
            padding: 0 f.map-deep-get($gridconfig, 'medium', 'col-padding');
        }

        &.small {
            @include m.item-list($columns, false, width);
        }

        &.medium {
            @include m.bp(md) {
                @include m.item-list($columns, false, width);
            }
        }

        &.large {
            @include m.bp(lg) {
                @include m.item-list($columns, false, width);
            }
        }
    }
}