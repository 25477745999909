@use '@/styles/global' as g;

.barContainer {
    color: g.$white;
    padding-bottom: 24px;

    @include g.bp(md) {
        padding-bottom: 48px;

        &.extendBarHeight {
            padding-bottom: 96px;
        }
    }

    a {
        color: inherit;
    }

    @include g.web2PageOverrides {
        background-color: var(--offwhite, #f5f5f5) !important;
        color: var(--base-contrast, #202020);
    }
}

.gridContainer {
    @include g.bp-max(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.resultStatusLine1 {
    @include g.set-font-weight('bold');
    font-size: 24px;
    margin-bottom: 8px;
}

.resultStatusLine2 {
    font-size: 16px;
}

.loadWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 24px;
}

.allTabCards {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 24px;

    @include g.bp(md) {
        flex-direction: row;
    }

    & > * {
        flex-grow: 1;
        margin-right: 8px;
    }
}

.cancelKG {
    @include g.bp(md) {
        margin-top: -48px;
    }
}
