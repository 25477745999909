@use './mixins' as m;
@use './variables' as v;
@use './zindex' as *;
@use './cssvars';

html {
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    @include m.base-font-styles;
}

@include m.box-sizing-reset;
@include m.base-image-styles;
@include m.cursor-styles;
@include m.section-styles;
@include m.button-reset;
@include m.table-rtetable;
@include m.img-rteimage;

.standard-mixtape-spacing:not(:last-child) {
    @include m.mixtape-spacing-simple();
}

.page-level-margins {
    margin-top: map-get(v.$page-layout-spacing, 'short');
    margin-bottom: map-get(v.$page-layout-spacing, 'short');

    @include m.bp(lg) {
        margin-top: map-get(v.$page-layout-spacing, 'venti');
        margin-bottom: map-get(v.$page-layout-spacing, 'venti');
    }

    @include m.bp(xl) {
        margin-top: map-get(v.$page-layout-spacing, 'gigantic');
        margin-bottom: map-get(v.$page-layout-spacing, 'gigantic');
    }
}

.standard-molecule-spacing {
    @include m.molecule-spacing-simple();

    // Remove molecule spacing in case being last child
    &:last-child {
        padding-bottom: 0;
    }

    // Remove headings double bottom spacing
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.nested-section-styles {
    &:last-of-type {
        margin-bottom: map-get(v.$page-layout-spacing, 'short') * -1;

        @include m.bp(lg) {
            margin-bottom: map-get(v.$page-layout-spacing, 'grande') * -1;
        }

        @include m.bp(xl) {
            margin-bottom: map-get(v.$page-layout-spacing, 'trenta') * -1;
        }
    }

    &:nth-of-type(odd) {
        background-color: var(--background-color);
    }

    &:nth-of-type(even) {
        background-color: var(--alternative-color);
    }
}

.hide-on-mobile {
    @include m.bp-max(md) {
        display: none;
    }
}

.QSIFeedbackButton {
    z-index: $modal - 1 !important;
}

div[data-sierra-chat-launcher] {
    height: 0;
    width: 0;
}