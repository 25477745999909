@use './mixins' as m;
@use './color-palette' as c;
@use './functions' as f;

// Basic display classes
// =================================
.display-block {
    display: block;
}

.inline-block {
    display: inline-block;
}

// UL list style type none
// =================================
.no-bullet {
    @include m.no-bullet;
}

.ul-decimal {
    list-style-type: decimal;
    padding: 0 f.calculate-rem(16px);
}

// No-padding
// =================================
.no-padding {
    padding: 0;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.no-padding-top {
    padding-top: 0px;
}

// Margin
// =================================
.no-margin {
    margin: 0;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-margin-left {
    margin-left: 0;
}

.no-margin-right {
    margin-right: 0;
}

// Visibility
// =================================
.hide {
    display: none;
    visibility: hidden;
}

.hide-sm {
    display: none;
    visibility: hidden;

    @include m.bp(md) {
        display: inherit;
        visibility: visible;
    }
}

.hide-md {
    @include m.bp(md) {
        display: none;
        visibility: hidden;
    }

    @include m.bp(lg) {
        display: inherit;
        visibility: visible;
    }
}

.hide-md-up {
    @include m.bp(md) {
        display: none;
        visibility: hidden;
    }
}

.hide-lg {
    @include m.bp(lg) {
        display: none;
        visibility: hidden;
    }
}

// Color Backgrounds
// =================================
.background-black {
    background-color: c.$black;
}

.background-white {
    background-color: c.$white;
}

.background-offwhite {
    background-color: c.$off-white;
}

.background-gray {
    background-color: c.$background-grey;
}

.background-gray-light {
    background-color: c.$gray-light;
}

.background-gray-medium {
    background-color: c.$gray-medium;
}

.background-blue {
    background-color: c.$blue;
}

.background-blue-light-ultra {
    background-color: c.$blue-ultra-light;
}

.background-blue-light {
    background-color: c.$blue-light;
}

.background-gray-dark {
    background-color: c.$gray-dark;
}

.background-blue-secondary {
    background-color: c.$blue-secondary;
}

// no border
// =================
.no-border {
    border: 0;
}

// Flex options
// ======================
.flex {
    display: flex;

    &-column {
        flex-direction: column;
    }

    &-column-reverse {
        flex-direction: column-reverse;
    }

    &-row {
        flex-direction: row;
    }

    &-row-reverse {
        flex-direction: row-reverse;
    }

    &-wrap {
        flex-wrap: wrap;
    }

    &.align-left {
        justify-content: flex-start;
    }

    &.align-justify {
        justify-content: space-between;
    }

    &.align-spaced {
        justify-content: space-around;
    }

    &.align-center {
        justify-content: center;
    }

    &.align-right {
        justify-content: flex-end;
    }

    &.align-middle {
        align-items: center;
    }
}