@use './mixins' as m;
@use './variables' as v;
@use './color-palette' as c;
@use './functions' as f;

// Headers
// =================================
// variables coming from _variables.scss Headers

// adding all headers h1-h6
@include m.font-print(v.$header-styles);

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: inherit;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    display: block;

    b {
        font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
    }
}

.eyebrow,
.eyebrowSm,
.eyebrowMd,
.eyebrowLg {
    color: c.$gray-dark;
    display: block;
    @include m.font-size(16px);
    line-height: 1.4375;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-weight: f.calculate-font-weight('black', v.$font-standard, v.$fonts-weight);
}

.eyebrow {
    &Sm {
        @include m.font-size(12px);
        font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
        line-height: 1.57;
        letter-spacing: 1px;
        width: 100%;
        margin-bottom: 8px;
    }

    &Md {
        @include m.font-size(14px);
        font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
        line-height: 1.57;
        width: 100%;
        margin-bottom: 8px;
    }

    &Lg {
        margin-bottom: 8px;
    }
}

.h1 {
    margin: 0 0 24px;

    &.alt {
        @include m.h1alt;
    }
}

.h2 {
    margin: 0 0 56px;
}

.h3 {
    margin-bottom: 32px;
}

.h4 {
    margin-bottom: 32px;
}

.h5 {
    margin-bottom: 16px;
}

.h6 {
    &.alt {
        font-weight: f.calculate-font-weight('black', v.$font-standard, v.$fonts-weight);
        @include m.font-size(15px);
        letter-spacing: 7px;
        line-height: 1.53;
        text-transform: uppercase;
    }
}

.paragraph {
    display: block;
    margin-top: 0;
    margin-bottom: f.calculate-rem(10px);

    @include m.bp(md) {
        margin-bottom: f.calculate-rem(12px);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

p,
.p {
    display: block;
    margin-top: 0;
    margin-bottom: f.calculate-rem(10px);

    @include m.bp(md) {
        margin-bottom: f.calculate-rem(12px);
    }

    @include m.font-size(14px);
    line-height: 1.57;

    &.small-copy {
        @include m.font-size(12px);
        line-height: 1.5;

        a {
            text-underline-offset: auto;
            text-decoration-thickness: auto;
        }
    }

    &.large-copy {
        @include m.font-size(16px);
        line-height: 1.5;
    }

    &.standard-copy {
        @include m.font-size(14px);
        line-height: 1.57;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.large-copy {
    @include m.font-size(16px);
    line-height: 1.5;
}

.standard-copy {
    @include m.font-size(14px);
    line-height: 1.57;
}

.small-copy {
    @include m.font-size(12px);
    line-height: 1.5;
}

.legal-copy,
.legal-copy p {
    @include m.font-size(12px);
    line-height: 1.5;
    font-weight: f.calculate-font-weight('book', v.$font-standard, v.$fonts-weight);

    // moved the padding to the legal-copy-container wrapper above.
    // tested that this won't affect SLAM. Salesflow code is already
    // overriding the padding that was here with their own styles anyway
    & a {
        @include m.unset-link-underline-properties;
    }

    [href^='tel:'] {
        color: inherit;
        text-decoration: none;

        &::before,
        &::after {
            content: none;
        }
    }
}

a {
    font-size: inherit;
    font-weight: inherit;
    color: c.$blue-secondary;
    cursor: pointer;
    white-space: normal;
    @include m.link-underline-properties;
    position: relative;
    .w2 & {
        color: var(--base-contrast-accent, #0000eb);
    }
}

.invalid-feedback {
    a,
    .text-link {
        color: inherit;
        font-weight: inherit;
        font-size: inherit;

        &:before {
            background: c.$red;
        }
    }
}

// Modifiers
// =================================
.uppercase {
    text-transform: uppercase;
}

.light-weight {
    font-weight: f.calculate-font-weight('light', v.$font-standard, v.$fonts-weight);
}

b,
strong,
.bold {
    font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
}

.black-weight {
    font-weight: f.calculate-font-weight('black', v.$font-standard, v.$fonts-weight);
}

.text-nowrap {
    white-space: nowrap;
}

.underline {
    text-decoration: underline;
}

// Basic Typography text align options
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-strikethrough {
    color: c.$gray-dark;
    text-decoration: line-through c.$gray-dark;
}

// Text color
// =================================
.text-color-black {
    color: c.$black;

    * {
        color: c.$black;
    }
}

.text-color-blue {
    color: c.$blue;

    * {
        color: c.$blue;
    }
}

.text-white {
    color: c.$white;
}

.text-black {
    color: c.$black;
}

.text-color-offwhite {
    color: c.$off-white;

    * {
        color: c.$off-white;
    }
}

.text-color-gray-light {
    color: c.$gray-light;

    * {
        color: c.$gray-light;
    }
}

.text-color-gray-medium {
    color: c.$gray-medium;

    * {
        color: c.$gray-medium;
    }
}

.text-color-gray-dark {
    color: c.$gray-dark;

    * {
        color: c.$gray-dark;
    }
}

.text-color-blue-light-ultra {
    color: c.$blue-ultra-light;

    * {
        color: c.$blue-ultra-light;
    }
}

.text-color-blue-light {
    color: c.$blue-light;

    * {
        color: c.$blue-light;
    }
}

.text-color-grey-dark {
    color: c.$gray-dark;

    * {
        color: c.$gray-dark;
    }
}

.text-color-blue-secondary {
    color: c.$blue-secondary;

    * {
        color: c.$blue-secondary;
    }
}

.text-color-purple {
    color: c.$purple;

    * {
        color: c.$purple;
    }
}

.text-color-raspberry {
    color: c.$raspberry;

    * {
        color: c.$raspberry;
    }
}

.text-color-green {
    color: c.$green;

    * {
        color: c.$green;
    }
}

.text-color-red {
    color: c.$red;

    * {
        color: c.$red;
    }
}

// text transform
// =================================
.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.caption {
    &,
    p {
        @include m.font-size(12px);
        color: c.$gray-dark;
        font-weight: f.calculate-font-weight('light', v.$font-standard, v.$fonts-weight);
        line-height: 1.57;

        & a {
            @include m.unset-link-underline-properties;
        }

        [href^='tel:'] {
            color: inherit;
            text-decoration: none;
        }
    }
}

.subhead {
    &,
    p {
        @include m.font-size(16px);
    }
}

.book {
    font-weight: f.calculate-font-weight('book', v.$font-standard, v.$fonts-weight);
}

.bold {
    font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
}

.medium {
    &,
    p {
        @include m.font-size(14px);
    }
}

.small {
    &,
    p {
        @include m.font-size(12px);
        line-height: 1.5;
    }
}

.large {
    &,
    p {
        @include m.font-size(16px);
        line-height: 1.5;
    }
}

.w2 {
    @include m.font-print(v.$w2-header-styles);

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        display: block;
        font-weight: f.calculate-font-weight('black', v.$font-standard, v.$fonts-weight);
        line-height: 1;
    }

    .p,
    .paragraph {
        display: block;
        margin-top: 0;
        margin-bottom: 12px;
        line-height: 1.5;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p,
    .p {
        @include m.font-size(14px);

        &.small-copy {
            @include m.font-size(12px);
        }

        &.large-copy {
            @include m.font-size(16px);
        }

        &.standard-copy {
            @include m.font-size(14px);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .small {
        &,
        p {
            @include m.font-size(12px);
            line-height: 1.5;
        }
    }

    .medium {
        &,
        p {
            @include m.font-size(14px);
        }
    }

    .large {
        &,
        p {
            @include m.font-size(16px);
            line-height: 1.5;
        }
    }

    .large-copy {
        @include m.font-size(16px);
    }

    .standard-copy {
        @include m.font-size(14px);
    }

    .small-copy {
        @include m.font-size(12px);
    }

    .eyebrow,
    .eyebrowSm,
    .eyebrowMd,
    .eyebrowLg {
        line-height: 1;
        text-transform: uppercase;
        @include m.font-size(12px);
    }

    .eyebrow {
        &Sm {
            @include m.font-size(14px);
        }

        &Md {
            @include m.font-size(16px);
        }

        &Lg {
            @include m.font-size(18px);
        }
    }
}
