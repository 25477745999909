@use './mixins' as m;
@use './variables' as v;
@use './color-palette' as c;
@use './functions' as f;

// These are temporary styles from WCS 12c which are being used
// by the care widgets. Care should update their widgets to
// include all these styles so we can remove them from our code.
.widget-styles,
.lpSwap {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 100%;
    max-width: unset !important;

    .row.no-padding,
    .column.no-padding {
        padding: 0;
        align-items: flex-start;
    }

    h5 {
        font-size: f.calculate-rem(20px);
        line-height: 1.22;
        letter-spacing: 0;
        font-weight: f.calculate-font-weight('black', v.$font-standard, v.$fonts-weight);
    }

    h6 {
        font-weight: f.calculate-font-weight('black', v.$font-standard, v.$fonts-weight);
    }

    .input-container {
        color: c.$red;
        border: solid 1px c.$gray-dark;
        box-sizing: border-box;
        height: 56px;
        margin-bottom: 16px;
        position: relative;
        width: 100%;

        input {
            border: 0;
            font-family: inherit;
            @include m.font-size(14px);
            height: 100%;
            outline: 0;
            padding: 24px 0 16px 10px;
            width: 100%;

            // sass-lint:disable no-vendor-prefixes
            &:-webkit-autofill {
                animation-name: onAutoFillStart;
                transition: background-color 50000s ease-in-out 0s;
            }

            &:not(:-webkit-autofill) {
                animation-name: onAutoFillCancel;
            }

            &.is-autofilled {
                border-left: 4px solid c.$blue-secondary;
            }
        }

        label {
            color: c.$gray-dark;
            @include m.font-size(14px);
            line-height: 20px;
            position: absolute;
            transform-origin: top left;
            transform: translate(0, 17px) scale(1);
            margin-left: 14px;
            transition: 0.1s ease-in-out;

            &.is-autofilled {
                transform: translate(0, 4px) scale(0.75);
                @include m.font-size(14px);
            }
        }

        &.active {
            label {
                transform: translate(0, 4px) scale(0.75);
            }

            input,
            textarea {
                border-left: 4px solid c.$blue-secondary;
            }
        }

        &.filled {
            box-shadow: none;

            label {
                transform: translate(0, 4px) scale(0.75);
            }

            input,
            textarea {
                border-left: 0;
                padding-left: 15px;
            }
        }

        &.invalid {
            border: 1px solid c.$red;

            &.active {

                input,
                textarea {
                    border-left: 4px solid c.$red;
                }
            }
        }

        &--error-msg {
            font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
            margin: 8px 0 0 0;
            color: c.$red;
        }
    }

    .tabs {
        position: relative;
        width: 100%;

        &__count {
            font-weight: normal;
        }

        &-row {
            // sass-lint:disable no-misspelled-properties
            scrollbar-width: none; // Firefox
            // sass-lint:disable no-vendor-prefixes
            -ms-overflow-style: none; // IE 10+

            // Chrome and Safari
            &::-webkit-scrollbar {
                display: none;
            }

            overflow-y: hidden;
            overflow-x: scroll;
            // height: 62px;
            position: relative;
            width: 100%;
            scroll-behavior: smooth;
        }

        [role='tablist'] {
            // sass-lint:disable no-misspelled-properties
            scrollbar-width: none; // Firefox
            // sass-lint:disable no-vendor-prefixes
            -ms-overflow-style: none; // IE 10+

            // Chrome and Safari
            &::-webkit-scrollbar {
                display: none;
            }

            font-size: 0;
            display: inline-flex;
            position: relative;
            padding: 0;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            margin: 0 auto;
            scrollbar-width: none;
            scrollbar-color: c.$gray-light transparent;
            scroll-behavior: smooth;

            &::after {
                content: '';
                background-color: c.$gray-light;
                width: 100%;
                position: absolute;
                height: 1px;
                bottom: 3px;
                z-index: 1;
                left: 0;
            }
        }

        &-title {
            &.is-active {
                pointer-events: none;
            }
        }

        .tabs-title {
            @include m.font-size(16px);
            font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
            line-height: 1.5;
            padding: 19px 0;
            position: relative;
            z-index: 2;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }

            &.is-active {
                &::before {
                    opacity: 1;
                }

                &:focus {
                    &::before {
                        height: 8px;
                        bottom: 0;
                    }
                }
            }

            &::before {
                background: c.$blue-secondary;
                content: '';
                width: 100%;
                height: 4px;
                bottom: 2px;
                left: 0;
                opacity: 0;
                pointer-events: none;
                position: absolute;
            }

            &:hover {
                &::before {
                    opacity: 1;
                }
            }

            &:focus {
                outline: none;

                &::before {
                    opacity: 1;
                }
            }
        }

        &-content {
            position: relative;
            height: auto;
            width: 100%;

            [role='tabpanel'] {
                // overflow: hidden; // leaving this until properly tested
                height: 0;

                img {
                    height: auto;
                    max-height: 100%;

                    // ensure that the placeholder lazy loaded image's height
                    // matches the height of the loaded image to avoid content shift
                    // as lazy images are loaded
                    &[data-src] {
                        height: 408px;
                    }
                }

                &.is-active {
                    height: auto;
                }

                &:not(.is-active) {
                    display: none;
                }
            }
        }

        .sticky {
            position: fixed;
            z-index: 3;
            background-color: c.$white;
            top: 0px;
            border: 1px solid c.$white;
            outline: 1px solid c.$white;
        }
    }

    .modal {
        &.hide {
            display: none;
        }
    }

    .modal-cover {
        background: c.$modal-background;
        left: 0;
        height: 100%;
        position: fixed;
        overflow-y: auto;
        top: 0;
        width: 100%;
        z-index: 1100 !important;

        &.hide {
            display: none;
        }

        .row {
            height: 100vh;

            >.column {
                height: 100%;
            }
        }

        .modal-cover-btn {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .modal {
            position: relative;
            background: c.$white;
            height: 100%;
            max-width: 600px;
            width: 100%;
            padding: 0 16px 24px;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 2;
            outline: none;

            @include m.bp(medium) {
                height: auto;
                width: 600px;
                padding: 0 40px 32px;
                overflow: visible;
            }

            &--title {
                color: c.$white;
                background-color: c.$purple;
                margin: 0 -16px;
                margin-bottom: 32px;
                padding: 12px;
                text-align: center;

                &:focus {
                    outline: none;
                }

                @include m.bp(medium) {
                    margin: 0 -40px;
                }
            }

            &--content {
                width: 100%;
                height: auto;
                padding-top: 25px;
                position: relative;

                >* .row {
                    height: auto;
                }

                &-title {
                    margin-top: 24px;
                    margin-bottom: 32px;
                }
            }

            .large-copy {
                b {
                    outline: none;
                }
            }

            &--content.canada {
                .modal--content {
                    &-title {
                        text-align: center;

                        display: block;

                        @include m.bp(medium) {
                            text-align: left;
                            display: inline-flex;
                            flex-direction: row-reverse;
                        }

                        &-copy {
                            margin-top: 24px;
                            width: 100%;

                            h4 {
                                @include m.font-size(36px);
                                line-height: 38px;
                                padding: 0 2px 8px 0;

                                @include m.bp(medium) {
                                    padding: 0;
                                    @include m.font-size(28px);
                                    line-height: 31px;
                                }
                            }

                            p {
                                padding: 0 14px;
                                @include m.font-size(14px);
                                line-height: 22px;

                                @include m.bp(medium) {
                                    padding: 0;
                                }
                            }

                            @include m.bp(medium) {
                                margin-top: 0;
                                padding-right: 35px;
                                width: auto;
                            }
                        }

                        &-img {
                            width: 100%;
                            display: inline-flex;
                            justify-content: center;

                            @include m.bp(medium) {
                                width: auto;
                                // display: inherit;
                            }

                            img {
                                width: 142px;
                                height: auto;

                                @include m.bp(medium) {
                                    width: 166px;
                                    height: 83px;
                                }
                            }
                        }
                    }

                    &-body {
                        &-copy {
                            font-weight: f.calculate-font-weight('black', v.$font-standard, v.$fonts-weight);
                            text-align: center;
                            letter-spacing: 0.4px;
                            @include m.font-size(16px);
                            line-height: 23px;

                            @include m.bp(medium) {
                                text-align: left;
                            }
                        }

                        &-link {
                            width: 100%;
                            text-align: center;
                            padding-top: 60px;

                            @include m.bp(medium) {
                                padding-top: 22px;
                                // padding-top: 60px;
                            }
                        }

                        &-button-row {
                            display: flex;

                            a {
                                margin-top: 8px;

                                @include m.bp(medium) {
                                    margin-top: 20px;
                                }

                                &:first-of-type {
                                    margin-right: 8px;
                                }

                                &:last-of-type {
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }
            }

            ul {
                line-height: 1.5;

                li {
                    margin-bottom: 8px;
                    padding-left: 2px;

                    &:last-of-type {
                        margin-bottom: 16px;
                    }
                }
            }

            %modalclose {
                background: c.$white;
                content: '';
                left: 0;
                position: absolute;
                height: 1px;
                top: 8px;
                width: 16px;
            }

            &-close {
                height: 18px;
                position: absolute;
                right: 16px;
                top: 14px;
                width: 18px;

                &::before {
                    @extend %modalclose;
                    transform: rotate(45deg);
                }

                &::after {
                    @extend %modalclose;
                    transform: rotate(135deg);
                }
            }

            .back-button {
                height: 18px;
                position: absolute;
                left: 16px;
                top: 14px;
                width: 18px;
                color: c.$white;
                @include m.font-size(22px);
            }
        }
    }

    [data-modal-trigger] {
        text-align: inherit;
    }

    .dropdown {
        margin-bottom: 16px;
    }

    .dropdown,
    .modal-cover .modal .dropdown {
        position: relative;
        width: 100%;
        cursor: pointer;

        &.disabled {
            pointer-events: none;

            .input-container {
                border: 0;

                input {
                    background-color: c.$gray-light;
                }
            }
        }

        &.active {
            outline: 0;
            z-index: 101;
            box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
        }

        &:focus {
            z-index: 101;

            input {
                border-left: 4px solid c.$blue;
            }
        }

        .input-container {
            margin-bottom: 0;
            z-index: 1;

            input {
                color: c.$black;
                background-color: c.$white;
                cursor: pointer;
            }

            &:not(.active)~ul {
                display: none;
            }

            &.active {
                border-bottom: 0;
                box-shadow: none;
                outline: none;

                ul {
                    display: block;
                    box-shadow: c.$active-shadow;

                    &:focus {
                        outline: 0;
                    }
                }

                label {
                    margin-left: 14px;
                }
            }

            &.active~ul {
                outline: none;
                box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
            }

            &.has-selection label {
                transform: translate(0, 4px) scale(0.75);
                @include m.font-size(14px);
            }

            &:not(.active).has-selection label {
                margin-left: 10px;
            }

            &::after {
                content: '';
                cursor: pointer;
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                width: 14px;
                height: 8px;
            }

            &.active::after {
                content: '';
                transform: rotate(180deg);
                top: 40%;
            }

            &.filled.active {
                input {
                    border-left: 4px solid c.$blue-secondary;
                }
            }
        }

        ul {
            box-sizing: border-box;
            list-style: none;
            margin-top: 0;
            padding-left: 0;
            position: absolute;
            width: 100%;
            max-height: 170px;
            overflow: auto;
            z-index: 100;

            &:not(.background-purple) {
                background-color: c.$white;
                border: solid 1px c.$gray-charcoal;
            }

            &.background-purple {
                border-top: 1px solid c.$white;

                li {
                    &:hover {
                        background-color: c.$white-drop-hover;
                    }

                    &.selected {
                        background-color: c.$white-trans;
                    }
                }
            }

            &.secondary-pane {
                border-left: 0;
                right: 0;
            }
        }

        li {
            @include m.font-size(14px);
            position: relative;
            background-color: c.$white;
            height: 56px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            transition: 0.1s ease-in-out;
            cursor: pointer;
            padding-left: 1em;

            button {
                display: block;
                width: 100%;
                height: 100%;
                text-align: left;
                color: inherit;
            }

            &:not(.dropdown-label) {
                &:hover {
                    // background-color: $black-drop-hover;
                    // krista changes below
                    background-color: c.$blue-light;
                }

                &.selected {
                    // background-color: $black-trans;
                    // krista changes below
                    background-color: c.$blue-secondary;
                    color: c.$white;
                }

                &:focus {
                    z-index: 1;
                }
            }

            &.dropdown-label {
                cursor: default;
            }
        }

        label {
            cursor: pointer;
        }
    }

    //Common

    %radio-check-focus {
        box-shadow: 0 0 5px c.$focus-blue-trans;
        outline: 2px solid c.$focus-blue-trans;
        outline-offset: 0.5px;
    }

    %radio-check-inputs {
        position: relative;

        input {
            z-index: 50;

            &[type='checkbox'],
            &[type='radio'] {
                //input cannot be display:none so it can remain tabbable.
                //this will hide it behind the pseudo element icon
                position: absolute;
                top: 4px;
                left: 4px;
                width: 10px;
                height: 10px;
                opacity: 0.0001;
            }

            &:focus+label {
                &::before {
                    @extend %radio-check-focus;
                }
            }
        }

        label {
            @include m.font-size(14px);
            display: block;
            position: relative;
            padding: 9px 0 9px 30px;
            line-height: 22px;
            cursor: pointer;
            z-index: 100;
        }
    }

    .checkbox-item {
        @extend %radio-check-inputs;
        position: relative;
        margin-top: 8px;
        margin-bottom: 0;

        .checkbox {
            position: relative;

            &:checked+label::before {
                content: '';
                background: c.$blue-secondary;
                border-color: c.$blue-secondary;
            }

            &:checked+label::after {
                content: '';
                background: url('https://www.siriusxm.com/ns/cms/static/global/css/minified/svg/checkmark_white.svg') no-repeat;
                background-size: contain;
                position: absolute;
                top: 14px;
                left: 2px;
                width: 16px;
                height: 16px;
            }
        }

        label {
            &::before {
                content: '';
                position: absolute;
                top: 10px;
                left: 0;
                background: c.$white;
                display: block;
                width: 20px;
                height: 20px;
                border: 2px solid c.$gray-dark;
            }
        }

        &.invalid {
            label {
                &::before {
                    border: 2px solid c.$red;
                }
            }
        }
    }

    .radio-item {
        @extend %radio-check-inputs;
        position: relative;
        margin-top: 8px;
        margin-bottom: 0;

        @at-root .form-container & {
            // need a task to go in salesflow and remove the 12px margin this is meant to override.
            // testable in the FLEPZ widget in the hero
            margin-top: 8px !important;
            margin-bottom: 0 !important;
        }

        .radio {
            &:checked+label::before {
                content: '';
                border-color: c.$blue-secondary;
            }

            &:checked+label::after {
                content: '';
                position: absolute;
                background: c.$blue-secondary;
                top: 14px;
                left: 4px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            &:focus+label {
                &::before {
                    border-radius: 50%;
                }
            }
        }

        label {
            &::before {
                content: '';
                position: absolute;
                top: 10px;
                left: 0;
                background: c.$white;
                display: block;
                width: 20px;
                height: 20px;
                border: 2px solid c.$gray-dark;
                border-radius: 50%;
            }
        }

        &.invalid {
            label {
                &::before {
                    border: 2px solid c.$red;
                }
            }
        }
    }

    .invalid {
        .radio-item {
            label {
                &::before {
                    border: 2px solid c.$red;
                }
            }
        }
    }

    .toggle-item {
        @extend %radio-check-inputs;
        position: relative;

        .toggle {
            &:checked+label::before {
                content: '';
                border-color: c.$blue-secondary;
                background: c.$blue-secondary;
            }

            &:checked+label::after {
                content: '';
                left: 22px;
            }
        }

        label {
            padding-left: 50px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: c.$gray-dark;
                display: block;
                width: 40px;
                height: 20px;
                border: 2px solid c.$gray-dark;
                border-radius: 10px;
                transition:
                    0.3s ease,
                    outline 0s;
            }

            &::after {
                content: '';
                position: absolute;
                background: c.$white;
                top: 2px;
                left: 2px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                transition: 0.3s ease;
            }
        }
    }

    .invalid-feedback p,
    .invalid-feedback a {
        color: c.$red;
        font-weight: f.calculate-font-weight('bold', v.$font-standard, v.$fonts-weight);
    }

    .text-link {
        font-size: inherit;
        font-weight: inherit;
        color: c.$blue-secondary;
        cursor: pointer;
        white-space: normal;
        @include m.link-underline-properties;
        position: relative;
        border: none;
    }

    /**
    * button mixins
   */
    /*
    * mixins for button styles
   */
    @mixin primary {
        background: var(--button-primary-surface, c.$blue);
        color: var(--button-primary-contrast, c.$white);

        &:hover,
        &:focus,
        &:active {
            color: var(--button-primary-border, c.$blue);
            background: transparent;
        }

        &::before {
            border-color: var(--button-primary-border, c.$blue);
        }
    }

    @mixin secondary {
        background: var(--button-secondary-surface, c.$blue-light);
        color: var(--button-secondary-contrast, c.$blue);

        &:hover,
        &:focus,
        &:active {
            color: var(--button-secondary-interaction-contrast, c.$blue);
            background: var(--button-secondary-interaction-surface, transparent);
        }

        &::before {
            border-color: var(--button-secondary-contrast, c.$blue-light);
        }
    }

    @mixin custom {
        background: var(--button-primary-surface, c.$blue);
        color: var(--button-primary-contrast, c.$white);

        &:hover,
        &:focus,
        &:active {
            color: var(--button-primary-contrast-hover, c.$blue);
            background: transparent;
        }

        &::before {
            border-color: var(--button-primary-border, c.$blue);
        }
    }

    @mixin dark {
        background: var(--button-primary-dark-surface, c.$white);
        color: var(--button-primary-dark-contrast, c.$blue);

        &:hover,
        &:focus,
        &:active {
            color: var(--button-primary-dark-surface, c.$white);
            background: transparent;
        }

        &::before {
            border-color: c.$white;
        }
    }

    @mixin disabled {
        background: var(--button-disabled-surface, c.$off-white);
        color: var(--button-disabled-contrast, c.$gray-dark);
        pointer-events: none;

        &::before {
            opacity: 0;
        }
    }

    @mixin full-width {
        max-width: none;

        @include m.bp(md) {
            max-width: none;
        }
    }

    @mixin short {
        padding: var(--button-short-padding, 14px 17px 12px);
        min-height: 44px;
        line-height: var(--button-short-line-height, 20px);
        @include m.font-size(12px);
    }

    @mixin text {
        margin: 0;
        padding: 0;
        width: fit-content;
        height: fit-content;
        min-height: 0px;
        background: none;
        border: none;
        color: var(--text-button-color, c.$black);
        text-transform: none;
        letter-spacing: unset;

        &::before {
            display: none;
        }
    }

    @mixin narrow {
        line-height: 1.25;
        margin-top: 0;
        min-height: 44px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        align-self: center;
        min-width: 80px;

        @include m.bp(md) {
            @include m.font-size(16px);
            min-width: 120px;
        }
    }

    @mixin button($style: primary) {
        // pass in a style that you need when using this mixin:
        // primary is default, but you can use:
        // secondary, dark
        // full-width or shot passed as booleans in buttonSize prop
        // disabled passed as boolean in disabled prop
        cursor: pointer;
        display: block;
        @include m.font-size(14px);
        font-size: var(--button-default-font-size, #{f.calculate-rem(14px)});
        font-weight: f.map-deep-get(v.$fonts-weight, v.$font-standard, bold);
        letter-spacing: var(--button-letter-spacing, 2px);
        margin-top: 32px;
        min-height: var(--button-default-min-height, 53px);
        line-height: var(--button-default-line-height, 1.42);
        padding: var(--button-default-padding, 17px);
        position: relative;
        transition:
            background 0.1s ease-in-out,
            color 0.1s ease-in-out;
        text-align: center;
        text-decoration: none;
        text-transform: var(--button-text-transform, uppercase);
        width: 100%;
        white-space: normal;
        border: none;
        border-radius: var(--button-border-radius, 0);

        &::before {
            background: transparent;
            border: var(--button-border-size, 4px) solid var(--button-primary-border, c.$blue);
            border-radius: var(--button-border-radius, 0);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            pointer-events: none;
            bottom: 0;
            opacity: 1;
            width: 100%;
            transition:
                border 0.1s ease-in-out,
                color 0.1s ease-in-out;
        }

        &:hover {
            background: transparent;
            text-decoration: none;

            &::before {
                opacity: 1;
            }
        }

        @include m.bp(md) {
            max-width: v.$button-max-width;
        }

        @if $style==primary {
            @include primary;
        }

        @if $style==secondary {
            @include secondary;
        }

        @if $style==custom {
            @include custom;
        }

        @if $style==dark {
            @include dark;
        }

        @if $style==disabled {
            @include disabled;
        }

        @if $style==full-width {
            @include full-width;
        }

        @if $style==short {
            @include short;
        }

        @if $style==narrow {
            @include narrow;
        }
    }

    @mixin paginationStandard {
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 50%;
        background-color: c.$white;
        color: c.$black;
        margin: 0px;

        &::before {
            border: none;
        }
    }

    @mixin paginationArrow {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0px;
        padding: 8px;
        border: 1px solid c.$secondary-deep-gray;
        background-color: c.$white;

        &::before {
            border: none;
        }

        &:hover {
            background-color: c.$black;
        }
    }

    /**
     * Style for <button>, including
     * CTA <button>
   */
    .button {
        @include button;

        &.primary {
            @include primary;
        }

        &.secondary {
            @include secondary;
        }

        &.custom {
            @include custom;
        }

        &.dark {
            @include dark;
        }

        &.disabled {
            @include disabled;
        }

        &.full-width {
            @include full-width;
        }

        &.short {
            @include short;
        }

        &.text {
            @include text;
        }

        &.narrow {
            @include narrow;
        }

        &.paginationStandard {
            @include paginationStandard;
        }

        &.paginationArrow {
            @include paginationArrow;
        }
    }

    .links-as-button {
        a {
            @include button;
        }

        &.primary a {
            @include primary;
        }

        &.secondary a {
            @include secondary;
        }

        &.dark a {
            @include dark;
        }

        &.disabled a {
            @include disabled;
        }

        &.full-width a {
            @include full-width;
        }

        &.short a {
            @include short;
        }

        &.narrow a {
            @include narrow;
        }
    }
}

// temporary hero widget button overrides
.widget-styles button.button {
    letter-spacing: var(--button-letter-spacing, 2px);
    font-size: var(--button-default-font-size, 16px);
}

.widget-styles button.button.primary {
    background: var(--color-default-surface-primary, c.$blue);

    &:before {
        border-color: var(--button-primary-border, c.$blue);
        border-width: var(--button-border-size, 4px);
    }

    &:hover,
    &:focus,
    &:active {
        color: var(--button-primary-border, c.$blue);
        background: transparent;
    }
}