@use '@/styles/global' as g;

.offerDetails {
    padding: 24px 0;

    @include g.bp(md) {
        padding: 32px 0;
    }
}

.pinFooterToBottom {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
        flex-grow: 1;
    }
}