@use '@/styles/global' as g;

.contentContainer,
.wrapper {
    width: 100%;
    height: 120px;
    text-decoration: none;
    color: inherit;
    background: g.$white;
}

.wrapper {
    box-shadow: g.$hover-shadow;
    display: flex;
    justify-content: space-between;

    @include g.web2PageOverrides {
        border-radius: 24px;
    }
}

.colorBorder {
    position: relative;
    display: flex;
    flex-basis: 120px;
    height: 100%;
    align-items: center;
}

.borderBackground {
    position: absolute;
    background-color: var(--border-color);
    width: 100%;
    height: 100%;
    opacity: 0.15;
    @include g.web2PageOverrides {
        border-radius: 0 24px 24px 0;
    }
}

.imageContainer {
    height: 80;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.radioImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    max-width: 120px;
}

.listenLiveText {
    $width: 95px;

    display: flex;
    align-items: center;
    width: $width;
    height: 40px;
    @include g.font-size(12px);
    @include g.set-font-weight('bold');
    color: g.$blue;
    letter-spacing: 1.7px;
    line-height: 20px;
    overflow-wrap: break-word;
    word-spacing: $width; // Forcing a word wrap
    text-align: center;
    text-transform: uppercase;
    margin-left: 25px;
    padding-right: 10px;
}

.playIcon {
    // icon is not centered in paint bounds
    // so numbers are compensating for that
    // to center and size icon to 48px
    position: absolute;
    top: calc(50% - 27px);
    width: 66px;
    z-index: 2;
    left: calc(27px - 50%);
}

.bannerContainer {
    display: flex;
    align-items: center;
    min-height: 34px;
    width: 100%;
    background: g.$purple;
    color: g.$white;
    @include g.set-font-weight('book');
    @include g.font-size(12px);
    width: 100%;
    padding: 8px 20px;
}
