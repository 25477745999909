@use '@/styles/global' as g;

.container {
    padding: 10px 0;

    a {
        padding-left: 4px;
        @include g.font-size(14px);
        @include g.set-font-weight('bold');
    }
}

.selection {
    color: var(--base-contrast, #202020);
    @include g.set-font-weight('bold');
}

.ymmTextWrapper {
    color: var(--base-contrast, #202020);
    @include g.font-size(12px);
}
