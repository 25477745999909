@use '@/styles/global' as g;

.containerWrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;

    .mainContainer {
        display: block;
        padding: 0 12px 32px 12px;

        .campaignBoxShadow {
            box-shadow: g.$active-shadow-heavy;

            @include g.web2PageOverrides {
                border-radius: 24px;
            }
        }

        .packageCopy {
            font-weight: 800;
            @include g.font-size(16px);
        }

        @include g.bp(sm) {
            width: 456px;
        }

        .packageFeatureBody {
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include g.font-size(14px);

            span {
                line-height: 1.3571;
            }
        }

        .largerText {
            @include g.font-size(16px);
            margin-bottom: 2px;

            span {
                line-height: 1.3125;
            }
        }

        .checkmarkVariant {
            justify-content: flex-start;
            height: auto;
        }
    }
}

.header {
    min-height: 272px;
    display: flex;
    align-items: center;

    .heroContainer {
        display: flex;
        justify-content: center;

        .image {
            max-width: 440px;
        }
    }
}
