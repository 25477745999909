@use '@/styles/global' as g;

.descriptionStart {
    padding-top: 48px;
}

.descriptionDetail {
    ul {
        li {
            p {
                margin-bottom: 0;
            }
        } 
    }
}

.descriptionEnd {
    padding-top: 40px;
}

.heroImage {
    @include g.web2PageOverrides {
        border-radius: var(--rounding-large, 24px);
    }
}

.descriptionImage {
    padding: 36px 0 16px;

    @include g.bp(lg) {
        padding-top: 0;

        @include g.web2PageOverrides {
            // Stopping the padding from cutting off the border radius:
            padding-bottom: 0;
        }
    }

    @include g.web2PageOverrides {
        border-radius: var(--rounding-large, 24px);
    }
}
