@use '@/styles/global' as g;

.heroContainer {
    padding-bottom: 32px;
    width: 100%;

    @include g.bp(md) {
        background-color: g.$gray-light;
        padding-bottom: 0;
    }
}

.channelContainer {
    width: 100%;
    position: relative;
}

.channelWrapper {
    margin-top: -60px;
    margin-bottom: -60px;

    @include g.bp(md) {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        right: 0;
        width: 100%;
        bottom: 10px;
    }
}

.ChannelTextWrapper {
    @include g.web2PageOverrides {
        padding: 0 16px;
    }

    @include g.bp(md) {
        display: block;
        flex: 0 1 100%;
        max-width: 440px;
        padding: unset;
    }
}

.audioIconWrapper {
    display: none;
    margin-bottom: 24px;
    color: g.$white;
    justify-content: center;

    span {
        margin-right: 7px;
    }

    @include g.bp(md) {
        display: flex;
    }
}

.banner {
    display: none;
    padding: 18px 0 18px 56px;
    height: 102px;
    color: g.$gray-charcoal;

    @include g.bp(md) {
        display: block;
    }
}
