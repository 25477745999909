@use './mixins' as m;

.grid-container {
    position: relative;
    @include m.grid-container;
    @include m.make-cssgrid;
}

@include m.make-cssgrid-spacings;
@include m.alignment-classes;

.no-padding {
    padding: 0;
}

.standard-molecule-spacing {
    @include m.molecule-spacing-simple();

    // Remove molecule spacing in case being last child
    &:last-child {
        padding-bottom: 0;
    }
}