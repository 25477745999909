@use '@/styles/global' as g;

.cardContainer {
    position: relative;
    width: 100%;
    height: 100%;

    @include g.bp(md) {
        min-height: 392px;
    }
}

.card {
    width: 100%;
    z-index: 1;

    @include g.bp(md) {
        position: absolute;
        right: 64px;
        top: 93px;
    }

    @include g.bp(lg) {
        right: 0px;
        width: 288px;
    }
}

.link {
    text-decoration: none;
    color: g.$blue-secondary !important;
}

.background {
    background-color: g.$off-white;
    height: 68px;
    position: absolute;
    width: 110%;
    bottom: 0;
    left: -5%;

    @include g.bp(md) {
        display: none;
    }
}