@use '.' as g;

//CSS Vars
:root {
    // Primary
    --black: #{g.$black};
    --blue: #{g.$blue};
    --white: #{g.$white};

    // Secondary
    --off-white: #{g.$off-white};
    --off-white-2: #{g.$off-white-2};
    --off-white-dark: #{g.$off-white-dark};
    --gray-light: #{g.$gray-light};
    --gray-medium: #{g.$gray-medium};
    --blue-ultra-light: #{g.$blue-ultra-light};
    --blue-light: #{g.$blue-light};

    // Gray shades
    --gray-dark: #{g.$gray-dark};
    --gray-semi-dark: #{g.$gray-semi-dark};
    --gray-charcoal: #{g.$gray-charcoal};
    --silver: #{g.$silver};
    --medium-black: #{g.$medium-black};
    --almost-black: #{g.$almost-black};
    --midnight: #{g.$midnight};
    --blacktwentypercent: #{g.$blacktwentypercent};
    --mine-shaft: #{g.$mine-shaft};
    --mine-shaft-trans: #{g.$mine-shaft-trans};
    --dark-charcoal: #{g.$dark-charcoal};
    --deep-gray: #{g.$secondary-deep-gray};

    // Blue shades
    --blue-secondary: #{g.$blue-secondary};
    --solitude-blue: #{g.$solitude-blue};
    --cornflower: #{g.$cornflower};

    // Purple shades
    --purple: #{g.$purple};
    --hover-purple: #{g.$hover-purple};
    --ltpurple: #{g.$ltpurple};
    --dkpurple: #{g.$dkpurple};

    // Green shades
    --green-light: #{g.$green-light};

    // default hero background
    --bg-blue: #{g.$bgBlue};

    // default sticky footer padding
    --sticky-footer-padding: 24px;

    // Other colors
    --raspberry: #{g.$raspberry};
    --highlighter: #{g.$highlighter};
    --cyan: #{g.$cyan};
    --red: #{g.$red};
    --green: #{g.$green};
    --orange: #{g.$orange};
    --vanilla: #{g.$vanilla};

    // Box Shadow
    --basic-shadow: 0 2px 15px -4px #00000026;

    // atlas-light
    --secondary-atlas-web-blue: #{g.$secondary-atlas-web-blue};
    --secondary-light-gray: #{g.$secondary-light-gray};
    --secondary-medium-gray: #{g.$secondary-medium-gray};
    --secondary-deep-gray: #{g.$secondary-deep-gray};

    // web2.0
    // Fonts temp names
    --temp-line-height-paragraph-medium: 1.5;

    // Dropdown Overlay temp names
    --temp-dropdown-overlay: rgba(0, 0, 0, 0.3);

    // Colors
    // Add more colors as needed
    --base-border-accent: #79a5ff;
    --base-button-primary-surface: #0000eb;
    --base-button-primary-border: rgba(0, 0, 0, 0);
    --base-button-primary-contrast: #fff;
    --base-button-primary-surface-on-hover: #0000c2;
    --base-button-primary-border-on-hover: rgba(0, 0, 0, 0);
    --base-button-primary-contrast-on-hover: #fff;
    --base-button-secondary-border: #d9d9d9;
    --base-button-secondary-surface: rgba(0, 0, 0, 0);
    --base-button-secondary-contrast: #202020;
    --base-button-secondary-border-on-hover: #b3b3b3;
    --base-button-secondary-surface-on-hover: rgba(0, 0, 0, 0);
    --base-button-secondary-contrast-on-hover: #202020;
    --base-button-tertiary-border: rgba(0, 0, 0, 0);
    --base-button-tertiary-surface: rgba(0, 0, 0, 0);
    --base-button-tertiary-contrast: #202020;
    --base-button-tertiary-border-on-hover: rgba(0, 0, 0, 0);
    --base-button-tertiary-surface-on-hover: #f7f7f7;
    --base-button-tertiary-contrast-on-hover: #202020;

    --base-background: #fff;
    --base-contrast: #202020;
    --base-contrast-subtle: #646464;
    --base-surface: #f7f7f7;
    --base-surface-strong: #f0f0f0;
    --base-contrast-accent: #0000eb;
    --base-border: #dbdbdb;
    --inverse-contrast-subtle: #b4b4b4;
    --inverse-contrast: #fff;
    --app-background-color: #111111;

    // Borders
    --border-radius-4: 4px;
    --border-radius-8: 8px;
    --border-radius-16: 16px;
    --border-radius-20: 20px;
    --border-radius-24: 24px;
    --border-radius-32: 32px;
    --border-radius-40: 40px;
    --border-radius-capsule: 1000px;

    --border-width-1: 1px;
    --border-width-2: 2px;
    --border-width-2-5: 2.5px;
    --border-width-3: 3px;
    --border-width-4: 4px;
    --border-width-6: 6px;
    --border-width-8: 8px;
    --border-width-16: 16px;

    // Icons
    --icon-large: 24px;
    --icon-medium: 20px;
}

body[data-theme='light'] {
    --brand-font-family-primary: 'Helvetica Now', arial, sans-serif;
    --button-text-transform: uppercase;
    --button-letter-spacing: 0;
    --button-border-radius: 40px;
    --brand-border-radius-24: var(--button-border-radius);
    --button-border-size: 1px;
    --size-button-primary-border: var(--button-border-size);
    --button-border-reset: 0;
    --button-default-min-height: none;
    --button-default-padding: #{g.calculate-rem(16px) g.calculate-rem(24px)};
    --button-default-font-size: #{g.calculate-rem(16px)};
    --button-default-line-height: #{g.calculate-rem(24px)};
    --button-short-font-size: #{g.calculate-rem(12px)};
    --button-short-line-height: #{g.calculate-rem(18px)};
    --button-short-padding: 13px 24px;
    --button-primary-surface: #{g.$secondary-atlas-web-blue};
    --button-primary-border: #{g.$secondary-atlas-web-blue};
    --button-primary-contrast: #{g.$white};
    --button-primary-dark-surface: #{g.$white};
    --button-primary-dark-border: #{g.$white};
    --button-primary-dark-contrast: #{g.$black};
    --button-secondary-surface: transparent;
    --button-secondary-border: #{g.$secondary-atlas-web-blue};
    --button-secondary-contrast: #{g.$secondary-atlas-web-blue};
    --button-secondary-interaction-surface: #{g.$secondary-atlas-web-blue};
    --button-secondary-interaction-contrast: #{g.$white};
    --button-secondary-dark-surface: transparent;
    --button-secondary-dark-contrast: #{g.$white};
    --button-secondary-dark-interaction-contrast: #{g.$black};
    --button-disabled-surface: #{g.$secondary-medium-gray};
    --button-disabled-contrast: #{g.$white};
    --button-loading-primary-surface: transparent;
    --button-loading-primary-contrast: #{g.$secondary-atlas-web-blue};
    --button-loading-primary-interaction-contrast: #{g.$white};
    --button-loading-secondary-surface: #{g.$secondary-atlas-web-blue};
    --button-loading-secondary-contrast: #{g.$white};
    --button-loading-secondary-interaction-contrast: #{g.$white};
    --button-loading-secondary-dark-surface: #{g.$white};
    --button-loading-secondary-dark-contrast: #{g.$black};
    --sticky-button-height: auto;
    --sticky-button-line-height: #{g.calculate-rem(18px)};
    --sticky-button-padding: 10px 24px;
    --color-default-surface-primary: #{g.$secondary-atlas-web-blue};
    --color-default-surface-primary-border: var(--button-primary-border);
    --color-default-contrast-primary: #{g.$white};
}
body[data-theme='dark'] {
    background-color: var(--app-background-color);
    color: var(--inverse-contrast);
}
