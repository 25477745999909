@use '@/styles/global' as g;

.channelNumber {
    display: block;
    margin-top: 32px;
    margin-bottom: 8px;
}

.socialText {
    display: block;
    margin-top: 24px;
}

.delimeter {
    display: inline-block;
    height: auto;
    margin: 0 8px;

    &::before {
        content: '|';
    }
}

.socialmedia {
    svg {
        fill: g.$white;
    }
}

.subhead {
    display: flex;
    flex-direction: row;
    @include g.set-font-weight('bold');
}

.explicitContent {
    margin: 3px;
    width: 18px;
    height: 18px;
    background-color: g.$white;
    color: g.$black;
    line-height: 18px;
    @include g.font-size(12px);
    text-align: center;
}

.channelNotification {
    display: block;
    margin-top: 12px;
    width: fit-content;
    border: 1px solid var(--channelPrimaryColor, g.$white);
    padding: 8px;
    color: g.$white;
}

.banner {
    display: none;

    @include g.bp(md) {
        display: block;
    }
}

.bannerText {
    display: block;
    margin: 10px 0;
    color: #736e7d;
    @include g.set-font-weight('bold');

    a {
        @include g.set-font-weight('bold');
    }
}

.phoneLink {
    color: g.$gray-dark;
}

.mobileDetails {
    display: block;
    padding-bottom: 48px;

    @include g.bp(md) {
        display: none;
    }

    .mobileDetailText {
        margin: 16px 0;
        letter-spacing: 1.7px;
        line-height: 20px;

        a {
            @include g.set-font-weight('bold');
            color: inherit;
        }
    }
}

.stickyChannelName,
.stickyChannelNumber {
    @include g.set-font-weight('bold');
    @include g.font-size(16px);
}

.stickyChannelNumber {
    color: g.$gray-charcoal;
    margin-left: 12px;
}

.audioIconWrapper {
    margin: 0 0 18px;
    padding: 12px 0 0;

    span {
        @include g.font-size(12px);
    }
}

.radioTextInfo {
    display: flex;
    flex-direction: column;
    color: g.$white;
    align-items: flex-start;
    margin-top: 8px;

    p:first-of-type {
        @include g.font-size(14px);
        @include g.set-font-weight('bold');
        margin: 0;
    }

    p:last-of-type {
        margin: 2px 0 8px;
        @include g.font-size(12px);
        line-height: 18px;
    }
}

.channelsHeroWrapper {
    margin-bottom: 95px;

    @include g.bp(md) {
        margin-bottom: unset;
    }
}

.channelHeroCardWrapper {
    height: 88px;

    @include g.bp(md) {
        min-height: 568px;
    }
}