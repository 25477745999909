@use '@/styles/global' as g;

.channelNumber {
    display: block;
    margin-top: 32px;
    margin-bottom: 8px;
}

.socialText {
    display: block;
    margin-top: 72px;
}

.socialmedia {
    svg {
        fill: g.$white;
    }
}

.subhead {
    @include g.set-font-weight('bold');
}

.channelCardWrapper {
    height: 72px;
    box-shadow: g.$active-shadow;

    .channelCardSelector {
        position: absolute;
        top: -32px;
        background: g.$white;
    }

    @media screen and (min-width: 375px) {
        height: 88px;
    }

    @media screen and (min-width: 767px) {
        height: unset;

        .channelCardSelector {
            position: unset;
            top: unset;
            background-color: unset;
        }
    }

    @include g.bp(md) {
        position: absolute;
        bottom: 0;
        right: 20px;
        min-width: 540px;
        height: unset;
    }

    @include g.bp(lg) {
        position: absolute;
        bottom: -28px;
        right: 20px;
        width: 245px;
        min-width: unset;
        height: unset;
    }
}

.customHeroTextWrapper {
    padding-bottom: 56px !important;

    @include g.bp(md) {
        padding-bottom: 24px !important;
    }
}

.background {
    background-color: g.$off-white;
    height: 88px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0px;

    @include g.bp(md) {
        display: none;
    }
}