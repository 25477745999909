body.loading {
    overflow: hidden;
    height: 100%;
}

:global(body:not(.loading)) {
    .pageLoadOverlay {
        display: none;
    }
}

:global(body.loading) {
    .pageLoadOverlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url('/resources/images/Stella-TailWag-v5.gif') var(--white) center center no-repeat;
        z-index: 2147483647;
    }
}