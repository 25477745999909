@use '@/styles/global' as g;

.channelCard {
    max-width: 392px;
    position: relative;
    flex: 0 1 100%;
    background: g.$white;

    @include g.web2PageOverrides {
        border-radius: 24px;
    }

    a {
        width: 100%;
        text-decoration: none;
        color: inherit;
        z-index: 2;
    }
}

.channelCard::before {
    @include g.web2PageOverrides {
        display: none;
    }
    content: '';
    position: absolute;
    top: 16px;
    height: 424px;
    left: -24px;
    z-index: 2;
    width: 100px;
    box-shadow: g.$hover-shadow;
    background-color: g.$white;
    border-bottom: 4px solid var(--border-color);
}

.channelCard::after {
    @include g.web2PageOverrides {
        display: none;
    }
    content: '';
    position: absolute;
    height: 392px;
    top: 32px;
    left: -48px;
    z-index: 1;
    width: 100px;
    box-shadow: g.$hover-shadow;
    background-color: g.$white;
    border-bottom: 4px solid var(--border-color);
}

.staticDisplay {
    position: relative;
    display: flex;
    cursor: pointer;
    height: 456px;
    max-width: 392px;
    padding: 21px 0 0 0;

    .contentWrapper {
        display: flex;
        overflow: hidden;
        min-width: 100%;
        height: 100%;
        z-index: 3;
        right: 0;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }

    .radioInfoContainer {
        display: flex;
        min-width: 159px;

        flex-direction: column;
        align-items: center;
    }

    .radioImageLink {
        margin: 32px 0 8px 0;
    }

    .radioImage {
        min-width: 80px;
        max-height: 80px;
        max-width: 165px;
    }

    .radioTextInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: g.$black;
        align-items: center;
        padding-top: 24px;

        p:first-of-type {
            @include g.font-size(14px);
            @include g.set-font-weight('bold', g.$font-standard);
            margin: 0;
        }

        p:last-of-type {
            margin: 2px 0 8px;
            color: g.$gray-dark;
        }

        p,
        a {
            @include g.font-size(12px);
        }
    }

    .albumContainerMobile {
        display: none;
    }

    .albumContainerMobile,
    .albumContainerDesktop {
        text-decoration: none;

        .button {
            background: none;
            border: none;
            padding-left: 0;
            height: unset;
            display: block;

            .kabab {
                display: none;
            }

            .albumWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                background-color: g.$gray-semi-dark;
                background-image: var(--background-image);
                background-size: cover;
                background-position: center;

                width: 184px;
                height: 184px;

                .albumImage {
                    width: 184px;
                    height: 184px;
                    max-width: none;
                }
            }
        }

        .colorBorder {
            height: 100%;
            height: 60px;
            position: relative;
            display: flex;
            align-items: center;
            text-decoration: none;
            transition-timing-function: ease-in-out;
            transition: width 0.3s;

            width: 100%;

            .borderBackground {
                display: none;
            }

            .listenLiveText {
                display: flex;
                position: relative;
                @include g.font-size(12px);
                @include g.set-font-weight('bold', g.$font-standard);
                color: g.$blue;
                letter-spacing: 1.7px;
                line-height: 20px;
                justify-content: center;
                letter-spacing: 1.7px;
                line-height: 20px;
                text-transform: uppercase;
                text-align: center;

                height: 100%;
                width: 100%;
                padding-top: 28px;
                opacity: 1;
            }

            .playIcon {
                // icon is not centered in paint bounds
                // so numbers are compensating for that
                // to center and size icon to 48px
                position: absolute;
                width: 66px;
                transition-timing-function: ease-in-out;
                transition:
                    top 0.3s,
                    left 0.3s,
                    opacity 0.3s;

                bottom: 0px;
                left: calc(50% - 33px);
                top: -28px;
                opacity: 1;
            }
        }
    }

    .albumContainerDesktop {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .shadow {
        box-shadow: g.$hover-shadow;
        width: 100%;
        height: 100%;
        background: g.$white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        @include g.web2PageOverrides {
            border-radius: 24px;
            overflow: hidden;
        }

        .desktopColorBorder {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 60px;
            opacity: 0.15;
        }
    }
}
