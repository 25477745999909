.w1 {
    @font-face {
        /* Design Name: Helvetica Now Text (Regular) */
        font-family: 'Helvetica Now';
        src: url('https://www.siriusxm.com/app-download/fonts/helvetica-now/HelveticaNowTextRegular_normal_normal_subset1.woff2')
            format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        /* Design Name: Helvetica Now Text Medium */
        font-family: 'Helvetica Now';
        src: url('https://www.siriusxm.com/app-download/fonts/helvetica-now/HelveticaNowTextMedium_normal_normal_subset1.woff2')
            format('woff2');
        font-weight: 500;
        font-style: medium;
        font-display: swap;
    }

    @font-face {
        /* Design Name: Helvetica Now Text Bold */
        font-family: 'Helvetica Now';
        src: url('https://www.siriusxm.com/app-download/fonts/helvetica-now/HelveticaNowTextBold_normal_normal_subset1.woff2')
            format('woff2');
        font-weight: 700;
        font-style: bold;
        font-display: swap;
    }

    @font-face {
        /* Design Name: Helvetica Display Black  */
        font-family: 'Helvetica Now';
        src: url('https://www.siriusxm.com/app-download/fonts/helvetica-now/HelveticaNowDisplayBlack_normal_normal_subset1.woff2')
            format('woff2');
        font-weight: 800;
        font-style: bolder;
        font-display: swap;
    }
}