@use '@/styles/global' as g;

.packageFeatureBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include g.font-size(14px);
}

.largerText {
    @include g.font-size(16px);
}

.packageCostCopySpacing {
    margin-top: 4px;
}