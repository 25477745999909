@use 'src/styles/global' as g;

.card,
.strip {
    width: 100%;
}

.card {
    display: none;
    padding-left: 48px;

    @include g.bp(md) {
        display: block;
    }
}

.strip {
    position: relative;
    z-index: 2;

    @include g.web2PageOverrides {
        padding: 0px 18px;
    }

    @include g.bp(md) {
        display: none;
    }
}